<template>
  <div id="verifikasi-master-data-farm-list" data-app>
    
    <div class="card card-custom border-lg border-0 border-primary">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Verifikasi Farm
          </h3>
        </div>
      </div>

      <!--begin::Table-->
        <div class="card-body pt-0 pb-0">

          <b-row class="mt-3 mb-2">
            <b-col cols="12" sm="6">
              <div class="input-icon h-40px max-w-300px float-sm-right">
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid h-40px"
                    placeholder="Search..."
                    v-model="search"
                  />
                  <span>
                    <span class="svg-icon svg-icon-lg">
                      <!--begin::Svg Icon-->
                      <inline-svg src="media/svg/icons/General/Search.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
            </b-col>
          </b-row>

          <v-data-table
            :headers="fields"
            :items="items"
            :page.sync="currentPage"
            :items-per-page="perPage"
            hide-default-footer
            class="elevation-1"
            disable-sort
            :loading="isBusy"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.action`]="{ item }">
                <b-button 
                v-b-tooltip.hover title="Lihat Farm"
                class="btn-icon mr-1"
                variant="clean"
                @click="$router.push(`/verifikasi/master-data/farm/${item.value}`)">
                  <span class="svg-icon svg-icon-lg m-0">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Binocular.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </b-button>

                <b-button 
                v-b-tooltip.hover title="Verifikasi Farm"
                class="btn-icon mr-1"
                variant="clean"
                @click="verifikasiData({ farm: item.value, status: 'verif' })">
                  <span class="svg-icon svg-icon-primary svg-icon-lg m-0">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Shield-check.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </b-button>
                
                <b-button 
                v-b-tooltip.hover title="Tolak Farm"
                class="btn-icon"
                variant="clean"
                @click="verifikasiData({ farm: item.value, status: 'tolak' })">
                  <span class="svg-icon svg-icon-danger svg-icon-lg m-0">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Shield-disabled.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </b-button>
            </template>
          </v-data-table>
        </div>
      <!--end::Table-->  

        <div class="card-footer">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="changePage" align="center"></b-pagination>
        </div>

    </div>

    <v-dialog
    v-model="dialogVerif"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Action data</v-card-title>

        <v-card-text>
          Apakah anda yakin?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="dialogVerif = false">
            BATAL
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onVerif">
            LANJUTKAN
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_COUNT } from "@/core/services/store/verification.module";
import VerifikasiService from "@/core/services/api/verifikasi/master/perusahaan.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "verifikasi-master-data-farm-list",
  data() {
    return {
      search: null,
      searchTimeout: null,
      isBusy: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      fields: [{ value: "text", text: "Farm" }, { value: "perusahaan", text: "Perusahaan" }, { value: "sistem_kandang", text: "Kandang" }, { value: "action", text: "Act" } ],
      items: [],
      dialogVerif: false,
      dataVerif: null
    }
  },
  watch: {
    search(val, old) {
      if (val != old) {
        clearTimeout(this.searchTimeout)

        this.searchTimeout = setTimeout(() => {
          this.getData(1, this.perPage, val)
        }, 750)
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentCount"
    ]),
  },
  methods: {
    changePage(val){
      if(this.currentPage == val) return
      this.isBusy = true
      this.items = []
      this.getData(val)
    },
    resetTable() {
      let count = this.currentCount
      count.master.farm = 0
      this.$store.dispatch(UPDATE_COUNT, count);
      this.items = []
      this.totalRows = 0
      this.currentPage = 1
    },
    getData (page = 1, perPage = this.perPage, search = this.search) {
      VerifikasiService.getPerusahaanFarmAll({page, per_page: perPage, search})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.resetTable()
                this.isBusy = false
                return;
            }
            this.items = res.data.data.data
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.total
            let count = this.currentCount
            count.master.farm = res.data.data.total
            this.$store.dispatch(UPDATE_COUNT, count);
            this.isBusy = false
        })
        .catch((err) => {
          this.resetTable()
          this.isBusy = false
          return ErrorService.status(err)
        })
    },
    verifikasiData(val) {
      this.dataVerif = val
      this.dialogVerif = true
    },
    onVerif () {
      if (! this.dataVerif) {
        return
      }
      VerifikasiService.verifPerusahaanFarm(this.dataVerif)
        .then((res) => {
            if (res.data.status) { 
              this.getData(this.currentPage)              
            }
            ErrorService.message(res.data)
            this.dialogVerif = false
            this.dataVerif = null
        })
        .catch((err) => {
          this.dialogVerif = false
          this.dataVerif = null
          return ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Master Data Farm" }]);
    if (!this.currentUser.menu.verifikasi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
  },
};
</script>

<style lang="scss">
#verifikasi-master-data-farm-list {
  .v-data-table {
    .v-data-table__wrapper {
      table {
        tbody {
          tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
            background: none !important;
          } 
        }
      }
    
      .v-data-table__mobile-row {
        min-height: 28px !important;
      }
    }
  }
}
</style>